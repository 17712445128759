.ctnContactPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .ctnTable {
        width: 90%;
        padding: 10px;
        border-radius: 15px;
        margin-top: 20px;
        background-color: #ced4da;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        max-height: 80vh;
        overflow-y: auto;

        .ctnMsgRow {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 10px;
            border-radius: 15px;
            background-color: #282c34;
            margin-bottom: 15px;
            .ctnTitleActions {
                h3 {
                margin: 5px;
                font-size: 22px;
                color: white;
                width: 80%;
                word-break: break-all;
            }
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            }
            

            p {
                margin: 5px;
                font-size: 16px;
                word-break: break-all;
                width: 90%;
                color: white;
            }

            .minorInfos {
                font-size: 12px;
            }

            .ctnAnswerInputs {
                display: none;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;

                .answerInputs {
                    background-color: #ced4da;
                    border-radius: 15px;
                    width: calc(100% - 20px); // 100% - 2 * 10px padding
                    border: 0;
                    padding: 5px 10px;
                    color: #282c34;

                    &:focus-visible {
                        outline: 0
                    }
                }

                .sendBtn {
                    margin: 0 10px;
                }
            }


            .actions {
                height: 25px;
                display: flex;
                align-self: flex-end;
                margin-top: -25px;
            }
        }
    }
}