.ctnLoginPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            label {
                margin-bottom: 2px;
                font-size: 1em;
            }
        }
    }

    button {
        align-self: center;
        margin-top: 20px;
    }
}

.error {
    color: whitesmoke;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    margin: 0;
    text-align: center;
    width: 100%;
}