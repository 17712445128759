.ctnThumbnail {
    width: 100%;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 2px 1px #a9abac;
    box-shadow: 0px 0px 2px 1px #a9abac;
    padding: 5px 10px;
    cursor: pointer;
    .deleteBtn {
        width: 20px;
        height: 20px;
        display: none;
        margin-bottom: -20px;
        z-index: 999;
    }

    &:hover {
        .deleteBtn {
            display: flex;
        }
    }
    h2 {
        color: #282c34;
        margin: 0;
        margin: auto;
        white-space: nowrap;
        font-weight: normal;
        font-size: 20px;
    }

    .ctnThumbMeanings {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        img {
            width: 20px;
        }

        p {
            font-size: 10px;
            color: #282c34;
            margin-left: 5px;
            white-space: nowrap;
        }
        
        
    }

    .ctnDefMeanings  {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

}