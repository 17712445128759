.ctnTopNav {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    .loggedInAsCtn {
        width:fit-content;
        span {
            display: block;
        }
    }
}