.ctnAnnouncements {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    .ctnMsgRow {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 15px;
        background-color: #282c34;
        padding: 10px;

        .ctnTitleActions {
            h3 {
                margin: 5px;
                font-size: 22px;
                color: white;
                width: 80%;
                word-break: break-all;
            }

            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }


        p {
            margin: 5px;
            font-size: 16px;
            word-break: break-all;
            width: 90%;
            color: white;
        }

        .minorInfos {
            font-size: 12px;
        }

    }
}