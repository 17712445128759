.appCtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #454552;
}

* {
  font-family: 'Roboto', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: #FCFCFC;
  font-size: 1.8em;
}

p,
li,
label,
strong,
button {
  font-size: 1.3em;
  color: #FCFCFC
}

li {
  list-style: none;
}

.active {
  color: #E85A71;
}

a {
  color: #FCFCFC;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #E85A71
  }
}


span {
  font-size: 1em;
  color: #FCFCFC;
}

header {
  width: 20%;
}

.ctnBody {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  min-height: 100vh;
  overflow: hidden;
}

// Tutorial => short.zkreations.com/m9CWPN

@mixin scroll-style($size: 10px,
  $thumb: none,
  $thumb-color: grey,
  $thumb-radius: 10px,
  $track-color: transparent,
  $track-radius: 10px) {

  // Respaldo para Firefox
  scrollbar-color: $thumb-color $track-color;
  scrollbar-width: thin;

  // Navegadores basados en webkit
  &::-webkit-scrollbar {
    width: $size;
    height: $size;

    &-track {
      background-color: $track-color;
      border-radius: $track-radius;
    }

    &-thumb {
      background-color: $thumb-color;
      background-image: $thumb;
      border-radius: $thumb-radius;
    }
  }
}


.scroll {
  @include scroll-style($size: var(--scroll-size, 10px),

    $thumb: var(--scroll-thumb, none),
    $thumb-color: var(--scroll-thumb-color, grey),
    $thumb-radius: var(--scroll-thumb-radius, var(--scroll-radius)),

    $track-color: var(--scroll-track, transparent),
    $track-radius: var(--scroll-track-radius, var(--scroll-radius)),
  );
}