.ctnAnnouncementsBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .ctnAnnouncementsComponent {
        max-height: 35vh;
        overflow-y: auto;
        width: 100%;
    }

    .ctnCreateAnnouncements {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        form {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .customInput {
                width: 50%;
                height: 30px;
                border-radius: 15px;
                border: none;
                outline: none;
                padding: 5px;
                justify-self: flex-start;
                margin-bottom: 15px;
            }

            .sendAnnouncementBtn {
                border-radius: 15px;
                border: none;
                outline: none;
                padding: 10px 15px;
                justify-self: flex-end;
                align-self: flex-end;
                margin-top: 15px;
                background-color: #282c34;
                color: white;
                font-weight: bold;
                cursor: pointer;
            }
        }

    }

}