.buttons {
    -webkit-box-shadow: 0px 0px 2px 1px #D8E9EF;
    box-shadow: 0px 0px 2px 1px #D8E9EF;
    border:none;
    padding:10px 20px;
    border-radius: 25px;
    background-color: #454552;
    font-weight: bold;
    font-size: 1em;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}


.activeBtn {
    background-color: white;
    color: #454552;
}

.disabled {
    opacity: 0.5;
}