nav {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #282c34;
    width: 100%;
    min-height: 100vh;
    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            list-style: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 20px 0;
            font-size: 1.5em;
            a {
                display: block;
                text-align: center;
                img {
                width: 85%;
                object-fit: contain;
                margin: 20px auto;
            }
            }
        }
    }

}