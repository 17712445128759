.ctnReports {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    .ctnMainCategoryBtn {
        margin-top: 10px;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        display: flex;

        button {
            margin: 10px 3%;
        }
    }

    .ctnTable {
        width: 90%;
        min-height: 75vh;

        -webkit-box-shadow: 0px 0px 2px 1px #D8E9EF;
        box-shadow: 0px 0px 2px 1px #D8E9EF;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 0px;
        margin-top: 20px;
        border-radius: 25px;
        .reportNote {
            margin: 10px 0px;
            font-size: 12px;
        }
        .ctnSubCategoryBtn {
            padding: 5px 5px;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            display: flex;
            background-color: #282c34;
            border-radius: 35px;
            width: 30%;
            min-width: 225px;
            -webkit-box-shadow: 0px 0px 2px 1px #a9abac;
            box-shadow: 0px 0px 2px 1px #a9abac;

        }

        .ctnActiveReport {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 5px;
            width: 100%;
            .card {
                width: 300px;

            }

        }

        .ctnReportsList {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            width: 95%;
            margin-top: 30px;
            overflow-x: auto;
            overflow-y: auto;
            .reportCard {
                margin-left: 10px;
                margin-right: 20px;
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }

}



.ctnJishoCreate {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: scroll;
    width: 100%;
    .card {
        margin: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}