.ctnCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #282c34;
    border-radius: 20px;
    .ctnContent {
        background-color: white;
        width: 100%;
        border-radius: 15px;

        h2 {
            color: #282c34;
            text-align: center;
            font-size: 50px;
            margin: 5px 0;
        }

        .ctnMeanings {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 90%;
            margin: auto;
            padding: 5px;
            flex-wrap: nowrap;
            overflow-x: auto;

            img {
                width: 20px;
                object-fit: contain;
                display: block;
                align-self: center;
            }
            .rowKanji {
                overflow-x: hidden !important;
                flex-direction: row !important;
                flex-wrap: wrap !important;
            }
            .meanings {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                flex-wrap: nowrap;
                overflow-x: auto;
                width: 100%;
                padding: 0px 10px;
                .ctgGroupMeanings {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    align-items: flex-start;
                    flex-wrap: nowrap;
                }

                p {
                    white-space: nowrap;
                    margin: 5px;
                }
            }

            .meaning {
                font-size: 18px !important;
            }

            p {
                color: #282c34;
                font-size: 12px;
                margin: 5px 10px;
                white-space: nowrap;
            }

            h4 {
                color: #282c34;
                font-size: 15px;
                text-transform: capitalize;
                margin: 0;
            }

            .addBtn {
                width: 20px;
                object-fit: contain;
                cursor: pointer;
            }
        }


    }

    h3 {
        font-size: 17px;
        margin: 5px 0px;
    }
}